export default defineNuxtRouteMiddleware((route) => {
  if (import.meta.server) return

  const voucher = route.query.voucher as string | null

  if (voucher) {
    useNuxtApp()
      .$api.order.getVoucher(voucher, {
        amount: 1e7,
      })
      .then(async (response) => {
        useOrder().setVoucher({
          code: response.code,
          discount: response.value,
        })

        const modal = await import('~/components/ui/UIVoucherAppliedModal.vue')
        useModal().open(modal.default, {
          voucher: response,
        })
      })
      .catch(() => null)
  }

  return
})
